.main-container {
    display: flex;
    flex-direction: column;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}


body {
    padding-bottom: 0px;
    flex-direction: column;
    display: flex;
}

.bg-primary {
    background-color: #ffffff !important;
    border-bottom: 1px solid #BDC3C7;
}

.btn-primary {
    background-color: #014670 !important;
}

.logo-img {
    width: 10rem;
}

.mobile-logo {
    height: 3rem;
    padding-left: 2%;
}

.nav-link {
    color: #42C6EA !important;
}

a {
    color: #42C6EA;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(66,198,234)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.nav-link:hover {
    text-decoration: underline !important;
}

.active {
    font-weight: bold !important;
}

.nav-link:focus {
    color: #42C6EA !important;
}

.navbar-custom {
    padding: 10px 14px;
}

.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.custom-footer {
    position: absolute;
    bottom: 0;
    /* Set the fixed height of the footer here */
    height: 60px;
    margin-top: auto;
    width: 100%;
    line-height: 60px;
    background-color: #f5f5f5;
    height: 60px;
}

.custom-footer>.container {
    padding-right: 15px;
    padding-left: 15px;
}

.block-color-header-blue {
    background-color: #42C6EA;
    padding-bottom: 2rem;
}

.block-color-header-blue.gray {
    background-color: #f7f7f7;
    padding-bottom: 2rem;
}

.block-color-header-blue.journey-result-header {
    background-color: #42C6EA;
    padding-bottom: 0rem;
}

.bg-ontheme {
    background-color: #42C6EA;
}

.block-color-header-heading {
    text-align: center;
    color: white;
    font-size: 2rem;
    padding-top: 1.5rem;
    font-weight: lighter;
    vertical-align: middle;
}

.block-color-header-heading.shared-header {
    padding-top: 0rem;

}

.header-wrapper {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #42C6EA;
}


.star-yellow {
    color: rgba(235, 235, 16, 0.198);
    border: black 1px solid;
}

.black {
    color: black !important;
}

.block-color-header-small {
    text-align: center;
    color: white;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;

}

.block-color-header-small-mob {
    text-align: center;
    color: white;
    font-size: 1rem;
    padding-top: 0rem;
    padding-bottom: 0.2rem;

}

.shade-blue {
    background-color: #f5f5f5;
    margin-left: 1rem;
    margin-right: 1rem;
}

.shade-blue h1 {
    color: #42C6EA !important;
    text-align: center;
}

.shade-blue p {
    color: #42C6EA !important;
    text-align: center;
}

.featured-item {
    text-align: center;
    color: #42C6EA !important;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
}

.center-div {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.stathead {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}

.statlower {
    text-align: center;
    width: 100%;
}

.emoji {
    font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol !important;
}

table {
    border-color: #42C6EA !important;
}

.table th,
.table td {
    padding: 0.25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    word-wrap: break-word !important;
}

thead {
    text-align: center;
    color: white;
    background-color: #014670;
}

tbody {
    background-color: white;
    color: black;
}


.train-final-dest {
    font-weight: bold;
}

.depart-time {
    font-weight: bold;
    text-align: center;
}

.depart-due {
    text-align: center;
    font-size: 0.8rem;
    padding-top: 0.1rem;
}

.plat-title {
    text-align: center;
}

.plat-number {
    font-weight: bold;
    text-align: center;
}

.sts-red {
    color: red;
}

.sts-amber {
    color: orange;
}

.sts-green {
    color: green;
}

.departure-info {
    display: inline-block
}

.platform-info {
    display: inline-block
}

.serviceinfo {
    word-wrap: normal;
}

.col-pad-override {
    padding-left: 0rem;
    padding-right: 0.75rem;
}

.train-user-journey-dest,
.train-duration {
    font-size: 0.8rem;
    font-weight: bold;
    color: gray;
}

.timestatus {
    white-space: nowrap;
}

.btn-ontheme {
    background-color: #42C6EA !important;
    color: #ffffff;
}

.fastest {
    background-color: #42C6EA !important;
    color: #ffffff;

    padding-right: 0.2rem;
    padding-left: 0.2rem;
    margin-left: 0.3rem;
}

.height-text-match {
    height: 1.5rem;
    margin-bottom: 0.25rem;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
}

.modal-icon {
    height: 6rem;
}

.homescreensimulationtext {
    font-size: 0.8rem;
    padding-top: 0.1rem;
}

.modal-add-body {
    padding-top: 0.9rem;
    text-align: center;
}

.modal-footer {
    background-color: #014670;
    color: white;
    text-align: left;
    display: inline;
}

.ios-share-icon {
    height: 0.9rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-bottom: 0.2rem;
}

.android-share-icon {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    height: 3rem;
}

.instruction-header {
    text-decoration: underline;
    padding-bottom: 0.2rem;
}

.reduced-right-padding {
    padding-right: 0rem !important;
}

.reduced-left-padding {
    padding-left: 0.2rem !important;
}

.alert-header {
    background-color: #eba832 !important;
    padding-bottom: 0rem;
}

.alert-content {
    text-align: left;
    color: white;
    font-size: 1.0rem;
    line-height: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.alert-header.red {
    background-color: #ff2400;
}

.block-color-header-blue.error-result-header {
    background-color: #42C6EA;
    padding-bottom: 1rem;
}

.block-color-header-blue.deviated-header {
    background-color: rgb(255, 255, 44);
    padding-bottom: 0.5rem;
    padding-top: 0.4rem;
    color: black !important;
    text-align: center;

}

.block-color-header-blue.pro-header {
    background-color: green;
    padding-bottom: 0.5rem;
    padding-top: 0.4rem;
    color: white !important;
    text-align: center;

}

.orange-class {
    color: #eba832;
}

.red-class {
    color: red;
    font-weight: normal;
}



.image-button {
    background-size: contain !important;
}

.imageRot {
    -webkit-animation: spin 2s ease-in-out;
    -moz-animation: spin 2s ease-in-out;
    animation: spin 2s ease-in-out;
}

@-moz-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(720deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(720deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(720deg);
    }
}

.bg-green {
    background-color: #50d890 !important;
}

.reverse-journey {
    text-align: center;
    color: white;

}

.featured-bg {
    background-color: #194da7 !important;
    color: white !important;
    margin-top: 0rem !important;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1rem !important;
}

.featured-bg.gray {
    background-color: #f7f7f7 !important;
}

.featured-bg.light {
    background-color: #42C6EA !important;
}

.featured-bg.white {
    background-color: #ffffff !important;
    color: black !important;
}

.image-cap {
    max-width: 80%;
    max-height: 30rem;
}

.image-cap.smaller {
    padding-top: 0.3rem;
    max-width: 10rem;
}

.title-left {
    text-align: left;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 0.3rem;
}

.subbb {
    text-align: left;
}

.box-border {
    border: white 1px solid;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.3rem;
}

.loading-text {
    color: rgb(198, 192, 192);
    text-align: center;
    font-size: 2rem;
}

.platform-hidden {
    color: #999;
    /* Adjust the color as needed */
    position: relative;
}

.estimated-platform {
    color: rgb(157, 157, 157);
}

* {
    padding: 0;
    margin: 0;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

.form-control:disabled {
    color: black !important;
}

.form-control:disabled,
.form-control {
    color: black !important;
}

.apple-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 8px 70px;
    /* Adjust the padding to match the size of the Google button */
    border: 2px solid black;
    /* Add a black border */
    background: black;
    font-size: 18px;
    /* Adjust the font-size to match the Google button */
    cursor: pointer;
    border-radius: 7px;
    color: white;
}

.apple-sign-in:hover {
    background: white !important;
    color: black;
}

.google-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 8px 70px;
    /* Add a black border */
    background: #DB4437;
    border: 2px solid #DB4437;
    font-size: 18px;
    /* Adjust the font-size to match the Google button */
    cursor: pointer;
    border-radius: 7px;
    color: white;
}

.google-sign-in:hover {
    background: white;
    color: #DB4437;
    border: 2px solid #DB4437;
}

.microsoft-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 8px 70px;
    /* Add a black border */
    background: #00A4EF;
    border: 2px solid #00A4EF;
    font-size: 18px;
    /* Adjust the font-size to match the Google button */
    cursor: pointer;
    border-radius: 7px;
    color: white;
}

.microsoft-sign-in:hover {
    background: white;
    color: #00A4EF;
    border: 2px solid #00A4EF;
}

/* Add this to your CSS file or style tag */
.expand-service {
    /* Add any other styles you want for the expand-service container */
    overflow: hidden;
}

.expand-service-enter {
    max-height: 0;
}

.expand-service-enter-active {
    max-height: 1000px;
    /* Set a large enough value to accommodate the content */
    transition: max-height 0.3s ease-in-out;
}

.expand-service-exit {
    max-height: 1000px;
}

.expand-service-exit-active {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
}

.star-icon {
    margin-bottom: 2px;
}

.arrow-class {
    margin-top: -0.2rem !important;
}

.green-button {
    background-color: rgb(25, 219, 25) !important;
}

.border-grey {
    border: 1px solid #dee2e6;
    border-spacing: 0px;
}

.btn-noback {
    background-color: white !important;
}

.subheader {
    text-align: center;
    color: white;
    padding-top: 1rem;
}


.onboard-status-text {
    font-size: 1rem;
}

.deviated-header {
    position: relative;
}

.pro-header {
    position: relative;
}

.close-btn {
    position: absolute;
    top: 50%;
    right: 1rem;
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1.5rem;
    transform: translateY(-50%);
    cursor: pointer;
}

.close-btn.white {
    color: white;
}

.loader {
    border: 0.3rem solid #f3f3f3; /* Light grey */
    border-top: 0.3em solid #3498db; /* Blue */
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
