.page-header {
    background-color: #CF000F;
    padding-inline: 3px;
}

.page-header h1 {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 0.95vw;
    text-align: center;
}

.bg-primary {
    color: #014670;
}